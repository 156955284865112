import { AnimatePresence, motion } from "framer-motion";
import { ReactComponent as CloseCrossIcon } from "../assets/close-cross.svg";

const backdropVariants = {
  visible: { opacity: 1 },
  hidden: { opacity: 0 },
};

const modalVariants = {
  visible: { y: 0 },
  hidden: { y: 50 },
};

export const Modal = ({
  cancelButtonText = 'Cancel',
  confirmButtonText = 'Confirm',
  children,
  isConfirmButtonDisabled,
  title = 'Modal',
  handleCloseModal,
  handleConfirm,
  modalClassName = '',
  confirmButtonBackground = 'bg-skyBlue'
}) => {

  return (
    <AnimatePresence mode="wait">
      <motion.div
        initial="hidden"
        animate="visible"
        exit="hidden"
        variants={backdropVariants}
        transition={{ duration: 0.3 }}
        id="staticModal"
        data-modal-backdrop="static"
        tabIndex="-1"
        aria-hidden="true"
        className={`fixed top-0 left-0 right-0 bottom-0 z-50 flex items-center justify-center w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 bg-black bg-opacity-20 ${modalClassName}`}
      >
        <motion.div
          variants={modalVariants}
          transition={{ duration: 0.5, stiffness: 150 }}
          className="relative w-full max-w-[400px] max-h-full"
        >
          <div className="relative bg-white rounded shadow">
            <div className="flex items-start justify-between py-4 px-6 border-b rounded-t bg-moonbeam">
              <h3 className="text-sm font-medium text-navySmoke">
                {title}
              </h3>
              <button
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded text-sm w-5 h-5 ml-auto inline-flex justify-center items-center"
                data-modal-hide="staticModal"
                onClick={handleCloseModal}
              >
                <CloseCrossIcon className="w-3 h-3" />
                <span className="sr-only">Close modal</span>
              </button>
            </div>

            <div className="p-6 space-y-6 bg-white">
              {children}
            </div>

            <div className="flex items-center justify-end px-6 py-4 space-x-2 border-t border-gray-200 rounded-b bg-moonbeam">
              <button
                data-modal-hide="staticModal"
                type="button"
                className="text-navySmoke bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-whisperBlue text-sm font-medium px-4 py-2.5 focus:z-10"
                onClick={handleCloseModal}
              >
                {cancelButtonText}
              </button>

              <button
                data-modal-hide="staticModal"
                type="button"
                disabled={isConfirmButtonDisabled}
                className={`text-white focus:ring-4 disabled:opacity-30 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center ${confirmButtonBackground}`}
                onClick={handleConfirm}
              >
                {confirmButtonText}
              </button>
            </div>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
}