import { useEffect } from "react";
import Questions from "./components/questions";
import { Sidebar } from "./components/sidebar";
import { AssessmentHeader } from "./components/header/AssessmentHeader";
import { ResultsReady } from "./components/ResultsReady";
import { fetchUserAssessivSections } from "../../actions/assessment";
import { assessmentStore, questionStore, timerStore } from "../../store";
import { setupSectionAndFirstTask } from "../../store/helpers";

export default function AssessmentPlatform() {
  const { resultsReady, setSectionData, setSectionExpand, addAnsweredQuestion, setUnExpandAllSection, assessmentId, assessmentVersionId, educatorId, setSubSectionData, setResultsReady } = assessmentStore();
  const { setNewQuestion } = questionStore();
  const resetTimeSpent = timerStore(state => state.resetTimeSpent);

  useEffect(() => {
    if (resultsReady) {
      return;
    }

    setSectionData([]);
    fetchUserAssessivSections(assessmentId, assessmentVersionId, educatorId)
      .then((response) => {
        const augmentedSections = response?.data?.map(
          (section, index) => {
            return {
              ...section,
              isExpanded: index === 0
            };
          }
        ) ?? [];
        setSectionData(augmentedSections)
          .then(() => {
            if (augmentedSections?.length) {
              setResultsReady(false);
              const currentSection = augmentedSections?.reduce(
                (accumulator, section, index) => {
                  if (!accumulator.sectionId && !section.isComplete) {
                    return {
                      ...section,
                      sectionIndex: index
                    };
                  }

                  return accumulator;
                }, {});

              if (!currentSection?.sectionId) {
                setResultsReady(true);
                const lastSectionId = augmentedSections?.[(augmentedSections?.length ?? 1) - 1]?.sectionId;
                if (lastSectionId) {
                  setUnExpandAllSection(true).then(() => {
                    setSectionExpand(lastSectionId, true);
                  });
                }
                return;
              }

              setUnExpandAllSection(true).then(() => {
                setSectionExpand(currentSection.sectionId, true);
                setupSectionAndFirstTask(
                  currentSection,
                  assessmentId,
                  assessmentVersionId,
                  setSubSectionData,
                  setNewQuestion,
                  educatorId,
                  addAnsweredQuestion
                );
              });
            } else {
              setResultsReady(true);
            }
          })
          .catch((error) => console.log(error));
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    return () => {
      resetTimeSpent();
    }
  }, []);


  return (
    <div className="App-header flex flex-col relative">
      {resultsReady && <ResultsReady />}

      <AssessmentHeader />

      <div className="flex flex-1 items-stretch overflow-hidden container mx-auto bg-gray-100">
        <Sidebar />

        <main className="flex flex-1 gap-2 p-2">
          <div className="flex-1 bg-white border border-softCloud rounded-md p-6">
            <Questions />
          </div>
        </main>
      </div>
    </div>
  );
}
