import { useEffect, useMemo, useState } from "react";
import { postSectionComplete } from "../../../../actions/assessment";
import { sendEducatorNotificationAboutCompletedAssessiv } from "../../../../actions/emails";
import { ConfirmationModal } from "../../../../components/ConfirmationModal";
import { logTime, setupSectionAndFirstTask } from "../../../../store/helpers";
import { assessmentStore, loginStore, questionStore } from "../../../../store";
import { useQuestionTimer } from "../../../../helpers/hooks/useQuestionTimer";

export const CompleteSection = ({ getCompleteFunction }) => {
  const { setNewQuestion, sectionOrder } = questionStore();
  const { fullName, email } = loginStore();
  const { assessments } = assessmentStore();
  const {
    setSectionExpand,
    sectionData,
    subSectionData,
    setSubSectionData,
    setResultsReady,
    assessmentId,
    assessmentVersionId,
    educatorId,
    answeredQuestions,
    addAnsweredQuestion,
    setSectionComplete
  } = assessmentStore();

  const [confirmModalVisible, setConfirmModalVisible] = useState(false);

  const areAllQuestionsAnswered = useMemo(() => {
    return subSectionData?.every?.(question => answeredQuestions.includes(question.questionId)) ?? false;
  }, [subSectionData, answeredQuestions]);

  const sendAssessmentCompletedNotification = () => {
    const completedAssessment = assessments?.find?.((assessment) =>
      assessment?.assessmentId?.toLowerCase() === assessmentId?.toLowerCase() &&
      assessment?.assessmentVersionId?.toLowerCase() === assessmentVersionId?.toLowerCase()
    );
    if (completedAssessment?.name?.length && completedAssessment?.educatorEmail?.length && fullName?.length && completedAssessment?.educatorEmail !== email) {
      sendEducatorNotificationAboutCompletedAssessiv({
        assessmentName: completedAssessment?.name,
        educatorEmail: completedAssessment?.educatorEmail,
        studentName: fullName
      })
        .catch(console.log);
    }
  }

  const uploadNextSection = (nextSectionIndex) => {
    const nextSection = sectionData?.[nextSectionIndex ?? sectionOrder];
    if (nextSection) {
      if (nextSection.isComplete) {
        return uploadNextSection(typeof nextSectionIndex === 'number' ? nextSectionIndex + 1 : sectionOrder);
      } else {
        return setupSectionAndFirstTask(
          {
            ...nextSection,
            sectionIndex: sectionOrder
          },
          assessmentId,
          assessmentVersionId,
          setSubSectionData,
          setNewQuestion,
          educatorId,
          addAnsweredQuestion
        )
          .then(() => {
            setSectionExpand(nextSection?.sectionId, true);
          })
          .catch((error) => console.log(error));
      }

    } else {
      sendAssessmentCompletedNotification();
      return setResultsReady(true);
    }
  }

  const handleCompleteSection = () => {
    const currentSection = sectionData?.[sectionOrder - 1];
    if (currentSection?.isComplete) {
      setResultsReady(true);
    } else {
      logTime()
        .then(() => {
          postSectionComplete(currentSection?.sectionId, assessmentId, assessmentVersionId, educatorId)
            .then(() => {
              setSectionComplete(currentSection?.sectionId);
              setConfirmModalVisible(false);
              return uploadNextSection();
            });
        });
    }
  }

  const handleCloseModal = (event) => {
    event.stopPropagation();
    setConfirmModalVisible(false);
  }

  const handleOnClickCompleteSection = (event) => {
    if (areAllQuestionsAnswered) {
      handleCompleteSection();
      handleCloseModal(event);
    } else {
      setConfirmModalVisible(true);
    }
  }

  useEffect(() => {
    getCompleteFunction?.(handleCompleteSection)
  }, [handleCompleteSection]);

  useQuestionTimer();

  return (
    <>
      {confirmModalVisible && (
        <ConfirmationModal
          description="You have questions left without an answer. Please make sure to answer all of questions before completing the section for the best score."
          checkBoxText="I am aware of my unanswered questions"
          confirmButtonText="Complete"
          title="Complete section"
          handleConfirm={handleCompleteSection}
          handleCloseModal={handleCloseModal}
        />
      )}

      <button
        className="rounded-lg border border-skyBlue bg-skyBlue text-white px-4 py-1 text-sm active:opacity-75"
        onClick={handleOnClickCompleteSection}
      >
        Complete the section
      </button>
    </>
  );
}