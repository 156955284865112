import { api } from "../../helpers/apiHelper";
import { ACTIVE_URL } from "../constants";
import { assessmentStore } from "../../store";

export const inviteUser = (email, firstName, lastName, password) => {
  return new Promise((resolve, reject) => {
    api
      .post(
        `${ACTIVE_URL}/api/users/invite`,
        {
          email,
          firstName,
          lastName,
          password,
        },
        { headers: { "Content-Type": "application/json" } }
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchStudentsResults = () => {
  const { studentsResults, setIsFetchingStudentResults } = assessmentStore.getState();

  if (!studentsResults?.length) {
    setIsFetchingStudentResults(true);
  }

  return new Promise((resolve, reject) => {
    api
      .get(
        `${ACTIVE_URL}/api/invite/students`,
        { headers: { "Content-Type": "application/json" } }
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      })
      .finally(() => setIsFetchingStudentResults(false));
  });
};

export const addEducator = ({ email, password, name }, roleId) => {
  return new Promise((resolve, reject) => {
    api
      .post(
        `${ACTIVE_URL}/api/users/${roleId}/invite`,
        { email, password, name },
        { headers: { "Content-Type": "application/json" } }
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchUsers = (pageSize = 10 , from = 0) => {
  const url = new URL(`${ACTIVE_URL}/api/users`);
  if (pageSize) {
    url.searchParams.set('pageSize', pageSize);
  }
  if (typeof from !== 'undefined') {
    url.searchParams.set('from', from);
  }
  return new Promise((resolve, reject) => {
    api
      .get(
        url.toString(),
        { headers: { "Content-Type": "application/json" } }
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const searchUser = (email = '', pageSize , from) => {
  const url = new URL(`${ACTIVE_URL}/api/users/search`);
  url.searchParams.set('email', email);
  if (typeof pageSize !== 'undefined') {
    url.searchParams.set('pageSize', pageSize);
  }
  if (typeof from !== 'undefined') {
    url.searchParams.set('from', from);
  }
  return new Promise((resolve, reject) => {
    api
      .get(
        url.toString(),
        { headers: { "Content-Type": "application/json" } }
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const changeRoleOfInvitedEducator = (email, roleId) => {
  return new Promise((resolve, reject) => {
    api
      .patch(
        `${ACTIVE_URL}/api/admin/auth/user/${email}/role/${roleId}`,
        null,
        { headers: { "Content-Type": "application/json" } }
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const deleteInvitedEducator = (educatorId) => {
  return new Promise((resolve, reject) => {
    api
      .delete(
        `${ACTIVE_URL}/api/users/educator/${educatorId}`,
        { headers: { "Content-Type": "application/json" } }
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const deleteInvitedStudent = (studentId) => {
  return new Promise((resolve, reject) => {
    api
      .delete(
        `${ACTIVE_URL}/api/users/student/${studentId}`,
        { headers: { "Content-Type": "application/json" } }
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getEducatorsList = (pageSize = 10, from = 0, email) => {
  return new Promise((resolve, reject) => {
    const url = new URL(`${ACTIVE_URL}/api/users/educator`);
    url.searchParams.set('pageSize', `${pageSize}`);
    url.searchParams.set('from', `${from}`);

    if (email) {
      url.searchParams.set('email', `${email}`);
    }

    api
      .get(url.toString())
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};