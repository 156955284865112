import { ACTIVE_URL, EMAIL_SERVICE_URL } from "../constants";
import { api } from "../../helpers/apiHelper";
import { assessmentStore } from "../../store";

export const fetchAssessmentList = () => {
  return new Promise((resolve, reject) => {
    api
      .get(`${ACTIVE_URL}/api/assessivs`, { headers: { "Content-Type": "application/json" } })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export const fetchPublishedAssessmentList = () => {
  return new Promise((resolve, reject) => {
    api
      .get(`${ACTIVE_URL}/api/assessiv/publish`, { headers: { "Content-Type": "application/json" } })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export const fetchCompletedAssessmentList = () => {
  return new Promise((resolve, reject) => {
    api
      .get(`${ACTIVE_URL}/api/user/assessivs/completed`, { headers: { "Content-Type": "application/json" } })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export const fetchPublishedAssessmentListByStudent = () => {
  return new Promise((resolve, reject) => {
    api
      .get(`${ACTIVE_URL}/api/user/assessivs/publish`, { headers: { "Content-Type": "application/json" } })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export const fetchUserAssessmentList = () => {
  return new Promise((resolve, reject) => {
    api
      .get(`${ACTIVE_URL}/api/user/assessivs`, { headers: { "Content-Type": "application/json" } })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export const suspendAssessiv = ({ assessivId, assessivVersionId, educatorId }) => {
  return new Promise((resolve, reject) => {
    api
      .patch(
        `${ACTIVE_URL}/api/assessiv/${assessivId}/version/${assessivVersionId}/educator/${educatorId}/suspend`,
        null,
        { headers: { "Content-Type": "application/json" } }
      )
      .then((res) => {
          resolve(res.data);
        })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchAssessment = (assessmentId) => {
  return new Promise((resolve, reject) => {
    api
      .get(`${ACTIVE_URL}/api/user/assessiv/${assessmentId}`, { headers: { "Content-Type": "application/json" } })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchUserAssessivSections = (assessivId, assessivVersionId, educatorId) => {
  return new Promise((resolve, reject) => {
    api
      .get(
        `${ACTIVE_URL}/api/user/assessiv/${assessivId}/version/${assessivVersionId}/educator/${educatorId}/sections`,
        { headers: { "Content-Type": "application/json" } }
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchSectionQuestions = (assessivId, assessivVersionId, educatorId, sectionId, subjectId) => {
  return new Promise((resolve, reject) => {
    api
      .get(
        `${ACTIVE_URL}/api/user/assessiv/${assessivId}/version/${assessivVersionId}/educator/${educatorId}/sections/${sectionId}/subject/${subjectId}`,
        { headers: { "Content-Type": "application/json" } }
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchUserAnswer = (data) => {
  return new Promise((resolve, reject) => {
    api
      .post(
        `${ACTIVE_URL}/api/user/question/answer`,
        data,
        { headers: { "Content-Type": "application/json" } }
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const postUserAnswer = ({ questionId, optionId, sectionId, assessivId, assessivVersionId, educatorId, isFlagged }) => {
  return new Promise((resolve, reject) => {
    api
      .post(
        `${ACTIVE_URL}/api/user/answer`,
        { questionId, optionId, sectionId, assessivId, assessivVersionId, educatorId, isFlagged },
        { headers: { "Content-Type": "application/json" } }
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const postAssessmentSettings = ({
  assessivId,
  assessivVersionId,
  educatorId,
  properties,
}) => {
  return new Promise((resolve, reject) => {
    api
      .post(
        `${ACTIVE_URL}/api/assessiv/settings`,
        {
          assessivId,
          assessivVersionId,
          educatorId,
          properties,
        },
        { headers: { "Content-Type": "application/json" } }
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export const logQuestionTime = (data) => {
  return new Promise((resolve, reject) => {
    api
      .post(
        `${ACTIVE_URL}/api/user/answer/time`,
        data,
        { headers: { "Content-Type": "application/json" } }
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchUserAnswerVersions = ({ assessivId, assessivVersionId, educatorId }) => {
  return new Promise((resolve, reject) => {
    api
      .get(
        `${ACTIVE_URL}/api/user/assessiv/${assessivId}/version/${assessivVersionId}/educator/${educatorId}/answers/versions`,
        { headers: { "Content-Type": "application/json" } }
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchUserAnswerVersionsByEducator = ({ assessivId, assessivVersionId, studentEmail }) => {
  return new Promise((resolve, reject) => {
    api
      .get(
        `${ACTIVE_URL}/api/educator/assessiv/${assessivId}/version/${assessivVersionId}/student/${studentEmail}/answers/versions`,
        { headers: { "Content-Type": "application/json" } }
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const configureUserAssessment = ({ assessivId, assessivVersionId }) => {
  return new Promise((resolve, reject) => {
    api
      .post(
        `${ACTIVE_URL}/api/user/assessivs/configure`,
        { assessivId, assessivVersionId },
        { headers: { "Content-Type": "application/json" } }
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const postSectionComplete = (sectionId, assessivId, assessivVersionId, educatorId) => {
  return new Promise((resolve, reject) => {
    api
      .post(
        `${ACTIVE_URL}/api/section/${sectionId}/complete`,
        { assessivId, assessivVersionId, educatorId },
        { headers: { "Content-Type": "application/json" } }
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchAssessmentSections = (assessmentId) => {

  return new Promise((resolve, reject) => {
    api
      .get(`${ACTIVE_URL}/api/assessiv/${assessmentId}/sections`, { headers: { "Content-Type": "application/json" } })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchAssessmentSection = (sectionId) => {

  return new Promise((resolve, reject) => {
    api
      .get(`${ACTIVE_URL}/api/section/${sectionId}`, { headers: { "Content-Type": "application/json" } })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchQuestion = (questionId) => {
  return new Promise((resolve, reject) => {
    api
      .get(`${ACTIVE_URL}/api/questionbank/${questionId}`, { headers: { "Content-Type": "application/json" } })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const postResult = (answerData) => {
  return new Promise((resolve, reject) => {
    api
      .post(`${ACTIVE_URL}/api/result`, answerData, { headers: { "Content-Type": "application/json" } })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getResult = ({ assessivId, assessivVersionId, educatorId, studentEmail, answerVersion }) => {
  return new Promise((resolve, reject) => {
    api
      .post(
        `${ACTIVE_URL}/api/report/assessiv/${assessivId}/version/${assessivVersionId}/student/${studentEmail}/educator/${educatorId}/answerversion/${answerVersion}`,
        null,
        { headers: { "Content-Type": "application/json" } }
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const inviteStudentToAssessiv = (email, name, assessivs, dueDate) => {
  const body = {
    email,
    name,
    dueDate,
    assessivs,
  };
  if (assessivs?.length) {
    body.assessivs = assessivs;
  }
  return new Promise((resolve, reject) => {
    api
      .post(
        `${ACTIVE_URL}/api/invite/student`,
        body,
        { headers: { "Content-Type": "application/json" } }
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const assignStudentToAssessiv = (email, name, assessivs, dueDate) => {
  return new Promise((resolve, reject) => {
    api
      .post(
        `${ACTIVE_URL}/api/assign/assessiv`,
        {
          email,
          name,
          assessivs,
          dueDate
        },
        { headers: { "Content-Type": "application/json" } }
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const flagQuestion = ({ isFlagged, questionId, sectionId, educatorId, assessivId, assessivVersionId }) => {
  return new Promise((resolve, reject) => {
    api
      .post(
        `${ACTIVE_URL}/api/user/question/flag`,
        { isFlagged, questionId, sectionId, educatorId, assessivId, assessivVersionId },
        { headers: { "Content-Type": "application/json" } }
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const reportQuestion = (questionId, comment) => {
  return new Promise((resolve, reject) => {
    api
      .post(
        `${ACTIVE_URL}/api/question/${questionId}/report`,
        { comment },
        { headers: { "Content-Type": "application/json" } }
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getEducatorUserScoreHistory = (accountId) => {
  return new Promise((resolve, reject) => {
    api
      .get(
        `${ACTIVE_URL}/api/educator/assessiv/user/${accountId}/completed`,
        { headers: { "Content-Type": "application/json" } }
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const searchRegularAssessivsToAssign = () => {
  return new Promise((resolve, reject) => {
    api
      .post(
        `${ACTIVE_URL}/api/extended/assessive`,
        { headers: { "Content-Type": "application/json" } }
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export const searchPracticeAssessivsToAssign = ({ section, domains }) => {
  return new Promise((resolve, reject) => {
    api
      .post(
        `${ACTIVE_URL}/api/extended/assessive/practice`,
        { section, domains },
        { headers: { "Content-Type": "application/json" } }
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
