import { useEffect, useRef, useState } from 'react';
import { motion } from "framer-motion";
import { createPortal } from "react-dom";
import { ManageStudents } from "./ManageStudents";
import { ManageEducators } from "./ManageEducators";
import { useClickOutside } from "../../../../../../../../../helpers/hooks/useClickOutside.ts";
import { COLOR_VARIANTS, ConfirmationModal } from "../../../../../../../../../components/ConfirmationModal";
import {
  fetchGroups,
  updateGroupActive,
} from "../../../../../../../../../actions/group";
import { groupsStore } from "../../../../../../../../../store";
import { getRole } from "../../../../../../../../../helpers/authentication.ts";

const MODAL_POSITION = {
  LEFT: 'left',
  RIGHT: 'right',
};

export const GroupOptions = ({
  group,
  educators = [],
  students = [],
  containerClassName = '',
  modalPosition = MODAL_POSITION.LEFT
}) => {
  const { setGroups } = groupsStore();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isManageEducatorsModalVisible, setIsManageEducatorsModalVisible] = useState(false);
  const [isManageStudentModalVisible, setIsManageStudentModalVisible] = useState(false);
  const [isDeactivateModalVisible, setIsDeactivateModalVisible] = useState(false);
  const buttonRef = useRef(null);
  const ref = useRef(null);
  const isSubModalOpened = useRef(false);
  const role = getRole();

  const toggleModalOpen = () => setIsModalOpen(prev => !prev);

  const handleDeactivateGroup = () => {
    if (group.id) {
      updateGroupActive(group.id, false)
        .then(() =>
          fetchGroups().then((data) => {
            setGroups(data?.data ?? []);
            setIsModalOpen(false);
            setIsDeactivateModalVisible(false);
          })
        );
    }
  }

  useEffect(() => {
    isSubModalOpened.current = isModalOpen && (isDeactivateModalVisible || isManageStudentModalVisible || isManageEducatorsModalVisible);
  }, [isModalOpen, isDeactivateModalVisible, isManageStudentModalVisible, isManageEducatorsModalVisible]);

  useClickOutside(ref, () => {
    if (!isSubModalOpened.current) {
      setIsModalOpen(false);
    }
  });

  const getModalStyle = () => {
    if (!buttonRef.current) return { top: 0, left: 0 };

    const positionKof = modalPosition === MODAL_POSITION.LEFT ? 27 : 140;

    const rect = buttonRef.current.getBoundingClientRect();
    return { top: rect.bottom + window.scrollY + 4, left: rect.left + window.scrollX - positionKof };
  };

  return (
    <div className={`relative ml-auto ${containerClassName}`}>
      <button ref={buttonRef} className="flex items-center justify-center p-1.5 h-8 w-8 gap-1 outline-0 rounded-full hover:bg-gray-200 active:bg-gray-300" onClick={toggleModalOpen}>
        <span className="block w-1 h-1 bg-gray-600 rounded-full"/>
        <span className="block w-1 h-1 bg-gray-600 rounded-full"/>
        <span className="block w-1 h-1 bg-gray-600 rounded-full"/>
      </button>

      {isDeactivateModalVisible && (
        <ConfirmationModal
          colorVariant={COLOR_VARIANTS.RED}
          description="This action will deactivate the group."
          title="Deactivate Group"
          handleCloseModal={() => setIsDeactivateModalVisible(false)}
          handleConfirm={handleDeactivateGroup}
        />
      )}

      {isModalOpen && (
        createPortal(
          <motion.div
            ref={ref}
            className="absolute z-20 mt-1 ml-6 transform"
            style={getModalStyle()}
            transition={{duration: 0.5, stiffness: 150}}
            variants={{
              visible: {y: 0},
              hidden: {y: 50},
            }}
          >
            <div className="flex flex-col gap-1 rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 px-4 py-2 bg-white">
              <ManageEducators
                groupId={group?.id}
                educators={educators}
                isModalVisible={isManageEducatorsModalVisible}
                setIsModalVisible={setIsManageEducatorsModalVisible}
              />

              <ManageStudents
                groupId={group?.id}
                students={students}
                isModalVisible={isManageStudentModalVisible}
                setIsModalVisible={setIsManageStudentModalVisible}
              />

              {role !== 'SubEducator' && (
                <button
                  className="text-burntRose text-sm text-left"
                  onClick={() => setIsDeactivateModalVisible(true)}
                >
                  Deactivate group
                </button>
              )}
            </div>
          </motion.div>,
          document.body
        )
      )}
    </div>
  );
}