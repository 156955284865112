import { useEffect, useState } from "react";
import { questionStore, timerStore } from "../../store";

export const useQuestionTimer = () => {
  const { isTimerPaused, questionSelected } = questionStore();
  const [currentQuestionId, setCurrentQuestionId] = useState(questionSelected);
  const setTimeSpent = timerStore(state => state.setTimeSpent);

  useEffect(() => {
    const resetAndSendTimeData = () => {
      setTimeSpent(0);
      setCurrentQuestionId(() => questionSelected);
    };

    if (questionSelected !== currentQuestionId || !questionSelected) {
      resetAndSendTimeData();
    }

    if (!questionSelected || isTimerPaused) {
      return;
    }

    const interval = setInterval(() => {
      setTimeSpent((prevTimer) => {
        return prevTimer + 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [questionSelected, currentQuestionId, isTimerPaused]);
};