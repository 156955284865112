import {useCallback, useState} from "react";

const isDefaultIncludes = (localStorageKey: string, value: string | number) =>
    () => JSON
        .parse(localStorage.getItem(localStorageKey) ?? '[]')
        .includes(value);

export const useLocalStorageIncludesArray = (
    localStorageKey:
    string, value: string | number
): [
    boolean,
    () => void,
    () => void
] => {
    const [includes, setIncludes] = useState<boolean>(isDefaultIncludes(localStorageKey, value));

    const handleToggleIncludes = useCallback(
        () => {
            const compareItems = JSON.parse(localStorage.getItem(localStorageKey) ?? '[]');

            const newArray = includes
                ? compareItems.filter((item: string | number) => item !== value)
                : [...compareItems, value];

            localStorage.setItem(
                localStorageKey,
                JSON.stringify(newArray)
            );

            setIncludes(!includes);
        },
        [includes, value, localStorageKey]
    );

    const resetLocalStorageArray = () => {
        localStorage.removeItem(localStorageKey);
        setIncludes(false);
    }

    return [includes, handleToggleIncludes, resetLocalStorageArray];
}